<template>
  <DialogBase
    :dialogWidth="900"
    :dialogModel="dialogModel"
    @closeDialog="(val) => closeDialog(val)"
    scrollable
  >
    <template v-slot:title>Inbound Api Management</template>

    <v-card elevation="0" class="py-4">
      <customerManagement />
      <div style="width: 100%; height: 25px"></div>
      <identityManagement />
    </v-card>

    <template v-slot:footer>
      <v-btn color="accent" outlined block @click="closeDialog()">Close</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import DialogBase from "@/components/dialogs/Base";
export default {
  components: {
    DialogBase,
    customerManagement: () => import("@/components/general/customerManagement"),
    identityManagement: () => import("@/components/general/identityManagement"),
  },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: true, default: null },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
  },
};
</script>
